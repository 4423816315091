<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Servicios Internos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Consulta</li>
                  <li class="breadcrumb-item active">Servicios Internos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-1">
                    <label>N° Servicio</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.servicio_id"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo servicio </label>
                    <select
                      id="tipo_servicio"
                      class="form-control form-control-sm"
                      v-model="filtros.tipo_servicio"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_servicio in listasForms.tipos_servicios"
                        :key="tipo_servicio.numeracion"
                        :value="tipo_servicio.numeracion"
                      >
                        {{ tipo_servicio.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Placa </label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.placa"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Conductor/Operador </label>
                    <select
                      id="persona_type"
                      v-model="filtros.persona_type"
                      class="form-control form-control-sm p-0"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option value="App\Conductor">Conductor</option>
                      <option value="App\Operario">Operario</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Nombre Conductor</label>
                    <input
                      type="text"
                      id="nombre"
                      class="form-control form-control-sm"
                      v-model="filtros.nombre"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Empresa </label>
                    <v-select
                      v-model="empresa"
                      label="razon_social"
                      class="form-control form-control-sm p-0"
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      :options="empresas"
                      :filterable="false"
                      @search="buscarEmpresas"
                      @input="getIndex()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Bloque </label>
                    <v-select
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="bloque"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.bloques"
                      @input="seleccionarBloque()"
                      class="form-control form-control-sm p-0"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Sitio </label>
                    <v-select
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="sitio"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="seleccionarSitio()"
                      class="form-control form-control-sm p-0"
                    ></v-select>
                  </div>

                  <div class="form-group col-md-2">
                    <label>Fecha inicial </label>
                    <input
                      type="date"
                      id="fecha_ini"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_ini"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Final </label>
                    <input
                      type="date"
                      id="fecha_fin"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_fin"
                    />
                  </div>
                  <div class="col-md-2">
                    <label>Estado </label>
                    <select
                      id="estado"
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2 float-right">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn bg-navy mt-3"
                        @click="getIndex()"
                      >
                        <i class="fas fa-search"></i><br />Buscar
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger mt-3"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i><br />Limpiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-striped table-hover table-sm text-nowrap mb-0"
                  style="vertical-align: middle"
                >
                  <thead>
                    <tr>
                      <th class="text-center"># Servicio</th>
                      <th class="text-center">Tipo Servicio</th>
                      <th class="text-center">Placa</th>
                      <th class="text-center">Conductor/Operador</th>
                      <th class="text-center">Empresa</th>
                      <th class="text-center">Fecha Inicial</th>
                      <th class="text-center">Fecha Final</th>
                      <th class="text-center">Bloque</th>
                      <th class="text-center">Sitio</th>
                      <th class="text-center">Horas Turno</th>
                      <th class="text-center">Estado</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(serv, index) in serviciosInternos.data"
                    :key="index"
                    style="font-size: 10px;"
                  >
                    <tr>
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm btn-default py-0"
                          data-toggle="modal"
                          data-target="#modal-form-detalles"
                          @click="$refs.CsConsultaDetalle.getIndex(serv)"
                        >
                          {{ serv.id }}
                        </button>
                      </td>

                      <td>
                        {{
                          serv.servicio != null
                            ? serv.nTipoServicio
                            : serv != null
                            ? serv.nTipoServicio
                            : ""
                        }}
                      </td>

                      <td>
                        {{
                          serv.equipo !== null && serv.equipo !== undefined
                            ? serv.equipo.placa
                            : ""
                        }}
                      </td>

                      <td>
                        <div
                          v-if="
                            serv.persona !== null && serv.persona !== undefined
                          "
                        >
                          {{
                            serv.persona_type == "App\\Conductor"
                              ? "Conductor: "
                              : "Operario: "
                          }}
                          {{ serv.persona.nombres }}
                          {{ serv.persona.apellidos }}
                          <br />
                          <span class="badge badge-info float-left">
                            {{ serv.persona.numero_documento }}
                          </span>
                        </div>
                      </td>
                      <td class="text-center">
                        {{ serv != null ? serv.empresa.razon_social : "" }}
                      </td>
                      <td>
                        {{ serv != null ? serv.fecha_ini : "" }}
                      </td>
                      <td>
                        {{ serv != null ? serv.fecha_fin : "" }}
                      </td>
                      <td v-if="serv.servicio != null">
                        {{
                          serv.bloque != null && serv.bloque != undefined
                            ? serv.bloque.nombre
                            : ""
                        }}
                      </td>
                      <td v-else>
                        <div v-if="serv != null">
                          {{
                            serv.bloque !== null && serv.bloque !== undefined
                              ? serv.bloque.nombre
                              : ""
                          }}
                        </div>
                      </td>
                      <td v-if="serv != null">
                        {{
                          serv.sitio !== null && serv.sitio !== undefined
                            ? serv.sitio.nombre
                            : ""
                        }}
                      </td>
                      <td v-else>
                        <div v-if="serv != null">
                          {{
                            serv.sitio !== null && serv.sitio !== undefined
                              ? serv.sitio.nombre
                              : ""
                          }}
                        </div>
                      </td>
                      <td class="text-center">
                        <div v-if="serv != null">
                          {{
                            serv.postulacion.det_solicitud_interna.nTurno !==
                              null &&
                            serv.postulacion.det_solicitud_interna.nTurno !==
                              undefined
                              ? serv.postulacion.det_solicitud_interna.nTurno
                              : ""
                          }}
                        </div>
                      </td>
                      <td class="text-center" v-if="serv != null">
                        <span
                          class="badge"
                          :class="
                            serv.estado == 1
                              ? 'badge-secondary'
                              : serv.estado == 2
                              ? 'badge-warning'
                              : serv.estado == 3
                              ? 'bg-navy'
                              : serv.estado == 4
                              ? 'bg-success'
                              : serv.estado == 5
                              ? 'badge-info'
                              : serv.estado == 6
                              ? 'badge-success'
                              : 'bg-light'
                          "
                        >
                          {{ serv.nEstado }}
                        </span>
                        <br />
                      </td>
                      <td class="text-center" v-else>
                        <span
                          class="badge"
                          :class="
                            serv.estado == 1
                              ? 'badge-primary'
                              : serv.estado == 3
                              ? 'bg-danger'
                              : 'bg-light'
                          "
                        >
                          {{ serv.nEstado }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="serviciosInternos.total">
                  Mostrando del <b>{{ serviciosInternos.from }}</b> al
                  <b>{{ serviciosInternos.to }}</b> de un total:
                  <b>{{ serviciosInternos.total }}</b> Registros
                </div>
                <div class="float-left" v-else>
                  <span class="badge badge-danger">
                    No hay registros para mostrar
                  </span>
                </div>
                <pagination
                  :data="serviciosInternos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right m-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <CsConsultaDetalle ref="CsConsultaDetalle" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import CsConsultaDetalle from "./CsConsultaDetalle.vue";

export default {
  name: "CsServicioIndex",

  components: {
    Loading,
    pagination,
    vSelect,
    CsConsultaDetalle,
  },

  data() {
    return {
      cargando: false,
      sitio: null,
      empresa: null,
      bloque: null,
      /* ruta: null, */
      serviciosInternos: {},
      postulacion: {},
      firmantes: {},
      listasForms: {
        tipos_servicios: [],
        estados: [],
      },
      checkPost: [],
      filtros: {},
      formPost: [],
      estados_modulo: [1, 2, 3, 4, 5, 6],
      empresas: [],
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.filtros.empresa_id = this.empresa ? this.empresa.id : null;
      let me = this;
      axios
        .get("/api/cs/consulta/servicios?page=" + page, {
          params: this.filtros,
        })
        .then(async (response) => {
          this.postulacion = response.data;
          this.serviciosInternos = response.data;
          this.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoServicio() {
      axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    getEstados() {
      axios
        .get("/api/lista/143", {
          params: { in_number: this.estados_modulo },
        })
        .then((response) => {
          this.listasForms.estados = response.data;
        });
    },

    getFirmantes() {
      axios.get("/api/lista/171").then((response) => {
        this.firmantes = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    seleccionarBloque() {
      if (this.bloque != null) {
        this.filtros.bloque_id = this.bloque.id;
      } else {
        this.filtros.bloque_id = null;
      }
    },

    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    seleccionarSitio() {
      if (this.sitio != null) {
        this.filtros.sitio_id = this.sitio.id;
      } else {
        this.filtros.sitio_id = null;
      }
    },

    buscarEmpresas(search) {
      if (search != "") {
        let me = this;
        this.cargando = true;
        var url = "api/admin/empresas/lista";
        axios
          .get(url, {
            params: {
              razon: search,
              linea_negocio_id: [4],
            },
          })
          .then(function(response) {
            me.empresas = response.data;
            me.cargando = false;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    limpiar() {
      this.filtros.servicio_id = "";
      this.filtros.tipo_servicio = "";
      this.filtros.placa = "";
      this.filtros.persona_type = "";
      this.filtros.nombre = "";
      this.filtros.bloque_id = "";
      this.bloque = null;
      this.filtros.bloque = null;
      this.filtros.sitio_id = "";
      this.sitio = null;
      this.filtros.sitio = null;
      this.filtros.fecha_ini = "";
      this.filtros.fecha_fin = "";
      this.filtros.estado = "";
      this.getIndex();
    },

    verDetalle(servicio) {
      return this.$router.push({
        name: "/Cs/ServiciosInternoForm",
        params: {
          servicio: servicio.servicio,
          postulacion: servicio,
          detalle: 1,
          presupuesto: 1,
        },
      });
    },

    permisoLineaNegocio(linea_negocio) {
      let lineasUser = this.$store.state.user.lineas_negocio;
      return (
        lineasUser.find((linea) => linea.id === linea_negocio) !== undefined
      );
    },
  },

  beforeMount() {
    this.getTipoServicio();
    this.getBloques();
    this.getSitios();
    this.getFirmantes();
    this.getEstados();
  },

  mounted() {
    this.getIndex();
  },
};
</script>
<style>
.bg-lightwarning {
  background-color: #fff3cd !important;
}

.bg-lightdanger {
  background-color: #f8d7da !important;
}

.bg-lightsuccess {
  background-color: #d4edda !important;
}
</style>
